import dayjs from "dayjs";
import { Application } from "models/application";
import { Area } from "models/area";
import { COUNT_HEADER } from "../constants/public";
import {
  Banner,
  BannerListParams,
  bannerContentSorter,
} from "../models/banner";
import apiClient from "../utils/apiClient";
import { memoize } from "lodash";
import axios, { AxiosError } from "axios";

export type BannerResponse<T> = {
  itemCount?: number;
  data: T;
};

export const getBanners = async (
  params: BannerListParams,
): Promise<BannerResponse<Banner[]>> => {
  const res = await apiClient.get("/banners", {
    params: params,
  });
  return {
    itemCount: parseInt(res.headers[COUNT_HEADER]),
    data: res.data,
  };
};

/**
 * Enriches and mutates banner object in place as per types.
 *
 * @param b banner object
 * @returns banner object
 */
export const enrichBanner = (b: Banner): Banner => {
  // Only parse dates if present, as dayJS will set current date for null values.
  if (b.valid_from) {
    b.valid_from = dayjs(b.valid_from);
  }
  if (b.valid_to) {
    b.valid_to = dayjs(b.valid_to);
  }
  b.banner_content.sort(bannerContentSorter);
  return b;
};

export const getBanner = async (id: string): Promise<Banner> => {
  const res = await apiClient.get(`/banners/${id}`);
  enrichBanner(res.data);
  return res.data;
};

export const createBanner = async (banner: Banner): Promise<number> => {
  const res = await apiClient.post("/banners", banner);
  return res.data;
};

export const updateBanner = async (banner: Banner): Promise<number> => {
  const res = await apiClient.put(`/banners/${banner.id}`, banner);
  return res.data;
};

export const getAreas = async (): Promise<BannerResponse<Area[]>> => {
  const res = await apiClient.get("/areas");
  return {
    itemCount: parseInt(res.headers[COUNT_HEADER]),
    data: res.data,
  };
};
export const memoizedGetAreas = memoize(getAreas);

export const getApplications = async (): Promise<
  BannerResponse<Application[]>
> => {
  const res = await apiClient.get("/applications");
  return {
    itemCount: parseInt(res.headers[COUNT_HEADER]),
    data: res.data,
  };
};

export const deleteBanner = async (bannerId: number): Promise<number> => {
  const res = await apiClient.delete(`/banners/${bannerId}`);
  return res.status;
};

export const memoizedGetApplications = memoize(getApplications);

export const getAxiosError = (err: unknown): AxiosError | undefined => {
  if (axios.isAxiosError(err)) {
    return err as AxiosError;
  }
  return undefined;
};
