import UnauthorizedUser from "./pages/Unauthorized";
import React from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { MainLayout } from "./components/MainLayout";
import ListBanners, { FilterFormLoaderData } from "./pages/ListBanners";
import {
  BannerErrorElement,
  BannerForm,
  BannerFormLoader,
} from "./pages/BannerForm";
import { RouterHandle } from "models/router";

const router = createBrowserRouter([
  {
    path: "/unauthorized",
    element: <UnauthorizedUser />,
    errorElement: <BannerErrorElement />,
    handle: {} as RouterHandle,
  },
  {
    path: "/",
    element: <MainLayout />,
    handle: {
      title: "routes.root",
    } as RouterHandle,
    children: [
      {
        path: "/",
        element: <Navigate to="/banners" replace={true} />,
        handle: {
          title: "routes.root",
        } as RouterHandle,
      },
      {
        path: "/banners",
        element: <Outlet />,
        handle: {
          title: "routes.banners",
        } as RouterHandle,
        children: [
          {
            index: true,
            element: <ListBanners isTemplate={false} />,
            loader: FilterFormLoaderData,
            handle: {
              title: "routes.listBanners",
            } as RouterHandle,
          },
          {
            path: "/banners/new",
            element: <BannerForm />,
            loader: BannerFormLoader,
            handle: {
              title: "routes.newBanner",
            } as RouterHandle,
          },
          {
            path: "/banners/:id",
            element: <BannerForm isEdit={true} />,
            errorElement: <BannerErrorElement />,
            loader: BannerFormLoader,
            handle: {
              title: "routes.editBanner",
            } as RouterHandle,
          },
        ],
      },
      {
        path: "/templates",
        element: <Outlet />,
        loader: FilterFormLoaderData,
        handle: {
          title: "routes.templates",
        } as RouterHandle,
        children: [
          {
            index: true,
            element: <ListBanners isTemplate={true} />,
            loader: FilterFormLoaderData,
            handle: {
              title: "routes.listTemplates",
            } as RouterHandle,
          },
          {
            path: "/templates/new",
            element: <BannerForm isTemplate={true} />,
            loader: BannerFormLoader,
            handle: {
              title: "routes.newTemplate",
            } as RouterHandle,
          },
          {
            path: "/templates/:id",
            element: <BannerForm isEdit={true} isTemplate={true} />,
            errorElement: <BannerErrorElement isTemplate={true} />,
            loader: BannerFormLoader,
            handle: {
              title: "routes.editTemplate",
            } as RouterHandle,
          },
        ],
      },
    ],
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
