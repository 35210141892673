import styled from "@emotion/styled";
import { Table, TableHead, TableCell, TableRow } from "@mui/material";

export const MtiTable = styled(Table)({
  backgroundColor: "transparent",
});

export const MtiTableHead = styled(TableHead)({
  backgroundColor: "transparent",
  border: "none",
});

export const MtiTableHeadCell = styled(TableCell)({
  color: "#12517b",
  borderBottom: "none",
  fontWeight: "bold",
});

export const MtiTableRow = styled(TableRow)({
  backgroundColor: "#E9F5FE",
  border: "none",
  borderCollapse: "separate",
});

export const MtiTableCell = styled(TableCell)({
  color: "#1B3C4A",
  padding: "5px",
  borderBottom: "1px solid white",
  borderLeft: "1px solid white",
  borderRight: "1px solid white",
  ":first-of-type": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  ":last-child": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
});
