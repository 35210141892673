import * as Yup from "yup";
import { Dayjs } from "dayjs";

export type Banner = {
  id?: number;
  status: boolean;
  type: "maintenance" | "incident";
  is_template: boolean;
  name?: string;
  valid_from?: Dayjs;
  valid_to?: Dayjs;
  created_at?: string;
  updated_at?: string;
  banner_content: BannerContentType[];
  applications: string[];
  countries: string[];
  app_visibility: boolean;
  metadata?: BannerMetadata;
};

type BannerMetadata = {
  email_alerts: boolean;
};

export type BannerListParams = {
  is_template: boolean;
  per_page?: number;
  page?: number;
  title?: string;
  message?: string;
  status?: number;
  application?: string;
  applications?: string[];
  countries?: string[];
  country?: string;
  valid_from?: string;
  valid_to?: string;
  active?: boolean;
  inactive?: boolean;
  sort?: string;
  sort_dir?: string;
};

export type BannerFilter = {
  title?: string;
  message?: string;
  applications?: string[];
  countries?: string[];
  country?: string;
  application?: string;
  active?: boolean;
  inactive?: boolean;
  is_template: boolean;
  valid_from?: string;
  valid_to?: string;
  status?: number;
};
export const defaultBannerFilter: BannerFilter = {
  is_template: false,
  active: false,
  inactive: false,
};
export const clearBannerFilter: BannerFilter = {
  is_template: false,
  active: false,
  inactive: false,
  title: "",
  message: "",
  applications: [],
  countries: [],
  valid_from: "",
  valid_to: "",
};

export type LanguageCode = "en" | "nl" | "fr" | "de" | "";

export type BannerContentType = {
  banner_id?: number;
  title: string;
  message: string;
  language_code: LanguageCode;
};

export function getDefaultContent(
  banner: Banner,
): BannerContentType | undefined {
  return banner.banner_content?.find((item) => item.language_code === "en");
}

export const defaultBannerContent: BannerContentType = {
  title: "",
  message: "",
  language_code: "",
};

export const defaultBanner: Banner = {
  status: true,
  type: "incident",
  is_template: false,
  banner_content: [defaultBannerContent],
  applications: [],
  countries: [],
  app_visibility: true,
  metadata: { email_alerts: true },
};

export const defaultTemplate: Banner = {
  status: false,
  type: "incident",
  is_template: true,
  banner_content: [defaultBannerContent],
  applications: [],
  countries: [],
  app_visibility: true,
};

export const buildValidationSchema = (t: (msg: string) => string) =>
  Yup.object().shape({
    id: Yup.number(),
    status: Yup.boolean(),
    type: Yup.string().required(t("errors.requiredError")),
    name: Yup.string().when("is_template", (criteria, field) =>
      criteria[0] ? field.required(t("errors.requiredError")) : field,
    ),
    is_template: Yup.boolean(),
    valid_from: Yup.date().when(["type", "is_template"], (type, field) => {
      if (type[0] == "maintenance" && !type[1]) {
        return field.required(t("errors.requiredError"));
      }
      return field;
    }),
    valid_to: Yup.date().when(["type", "is_template"], (type, field) => {
      if (type[0] == "maintenance" && !type[1]) {
        return field
          .required(t("errors.requiredError"))
          .min(
            Yup.ref("valid_from"),
            t("errors.validToLaterThanValidFromError"),
          );
      }
      return field;
    }),
    created_at: Yup.string(),
    updated_at: Yup.string(),
    banner_content: Yup.array()
      .of(
        Yup.object().shape({
          banner_id: Yup.number(),
          title: Yup.string().required(t("errors.titleRequiredError")),
          message: Yup.string().required(t("errors.messageRequiredError")),
          language_code: Yup.string().required(t("errors.requiredError")),
        }),
      )
      .required(t("errors.noBannerContendError"))
      .min(1, t("errors.minimumBannerContendError")),
    applications: Yup.array()
      .required(t("errors.requiredError"))
      .min(1, t("errors.noApplicationsError")),
    countries: Yup.array()
      .required(t("errors.requiredError"))
      .min(1, t("errors.noAreasError")),
  });

export const bannerContentSorter = (b: BannerContentType) =>
  b.language_code == "en" ? -1 : 1;
