import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { upperFirst } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useReducer } from "react";
import { Application } from "models/application";
import { Area } from "models/area";
import { BannerFormLoaderData } from "pages/BannerForm";
import { useTranslation } from "react-i18next";
import { FilterBanners } from "../components/BannerFilters";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  BannerResponse,
  deleteBanner,
  getBanners,
  memoizedGetApplications,
  memoizedGetAreas,
} from "../api/banners";
import TableContainer from "@mui/material/TableContainer";
import { CircularProgress, Grid, TablePagination } from "@mui/material";
import { Banner } from "../models/banner";
import BannerTable from "../components/BannerTable";
import {
  BannerListQueryReducer,
  FilterBannersInitial,
  FilterTemplatesInitial,
} from "../reducers/bannerListQuery";

export const FilterFormLoaderData = async (): Promise<BannerFormLoaderData> => {
  return {
    applications: await memoizedGetApplications(),
    areas: await memoizedGetAreas(),
  };
};

export type BannerListProps = {
  isTemplate: boolean;
};

const initialValue = (isTemplate: boolean) =>
  isTemplate ? FilterTemplatesInitial : FilterBannersInitial;

const ListBanners = ({ isTemplate }: BannerListProps) => {
  const nav = useNavigate();
  const [queryParams, queryParamsDispatch] = useReducer(
    BannerListQueryReducer,
    isTemplate,
    initialValue,
  );

  const { isLoading, isFetching, data, refetch } = useQuery<
    BannerResponse<Banner[]>
  >({
    queryKey: ["banners", queryParams],
    queryFn: () => getBanners(queryParams),
  });
  const tCommon = useTranslation("common");
  const { t } = useTranslation("app");
  const confirm = useConfirm();
  const promptDelete = async (
    isTemplate: boolean,
    name: string,
    id: number | undefined,
  ) => {
    if (id === undefined) return;
    const confirmText = {
      title: t("forms.deleteConfirm.confirmTitle"),
      description: t("forms.deleteConfirm.confirmBody", {
        type: isTemplate ? "Template" : "Banner",
        name: name,
      }),
      confirmationText: tCommon.t("general.ok"),
      cancellationText: tCommon.t("general.cancel"),
    };
    confirm(confirmText)
      .then(async () => {
        const status = await deleteBanner(id);
        if (status == 204) {
          enqueueSnackbar(
            `${t("success.successfullyDeleted", {
              type: upperFirst(isTemplate ? "Template" : "Banner"),
            })}`,
            {
              variant: "success",
            },
          );
        } else {
          enqueueSnackbar(
            `${t("errors.FailedToDeleteError", {
              type: upperFirst(isTemplate ? "Template" : "Banner"),
              name: name,
            })}`,
            {
              variant: "error",
            },
          );
        }
        refetch();
      })
      .catch(() => {
        return;
      });
  };
  useEffect(() => {
    queryParamsDispatch({ type: "reset", init: initialValue(isTemplate) });
  }, [isTemplate]);

  const pathPrefix = isTemplate ? "/templates" : "/banners";

  return (
    <Grid container spacing={2}>
      <Grid item xs={2} sm={2}>
        <FilterBanners
          key={pathPrefix}
          isTemplate={isTemplate}
          dispatch={queryParamsDispatch}
        />
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="mtiAddButton"
              component={Link}
              to={`${pathPrefix}/new`}
              sx={{ marginBottom: 2 }}
            >
              <FontAwesomeIcon style={{ fontSize: "20px" }} icon={faPlus} />
            </Button>
          </Grid>
          <Grid item xs={6} textAlign={"right"}>
            {isFetching && <CircularProgress size={35} />}
          </Grid>
        </Grid>
        <TableContainer>
          <BannerTable
            isTemplate={isTemplate}
            isLoading={isLoading}
            banners={data?.data || []}
            defaultCount={queryParams.per_page || 5}
            onClick={(id) => nav(`${pathPrefix}/${id}`)}
            onDeleteClick={(isTemplate, name, id) =>
              promptDelete(isTemplate, name, id)
            }
          />
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={data?.itemCount || 0}
          rowsPerPage={queryParams.per_page || 0}
          page={queryParams.page || 0}
          onPageChange={(_e, v) => {
            queryParamsDispatch({ type: "page", page: v });
          }}
          onRowsPerPageChange={(e) => {
            queryParamsDispatch({
              type: "per_page",
              per_page: parseInt(e.target.value),
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ListBanners;
export type FilterFormLoaderData = {
  applications: BannerResponse<Application[]>;
  areas: BannerResponse<Area[]>;
};
