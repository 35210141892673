import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FlagIcon from "@mui/icons-material/Flag";
import ArticleIcon from "@mui/icons-material/Article";
import { useTranslation } from "react-i18next";
import AppBarComponent from "./AppBar";
import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { closedDrawerWidth, drawerWidth } from "../utils/theme";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import getHostVars from "../utils/hostVars";
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "50px",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgb(0, 139, 199)",
  border: "none",
  height: "60px",
  padding: theme.spacing(2),
  cursor: "pointer",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  border: "none",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface MiniDrawerProps {
  children?: ReactNode;
}

export default function MiniDrawer({ children }: MiniDrawerProps) {
  const [open, setOpen] = React.useState(false);
  const app = useTranslation("app");
  const nav = useNavigate();
  const location = useLocation();
  const envVars = getHostVars(window.location.hostname);

  const handleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent
        open={open}
        handleDrawer={handleDrawer}
        data-testid="app-bar"
      />
      <Drawer
        variant="permanent"
        open={open}
        data-testid="mini-drawer"
        PaperProps={{ sx: { border: 0 } }}
      >
        <DrawerHeader
          onClick={() => {
            window.location.href = envVars.MTINFO_HOME_URL;
          }}
        >
          <img
            src="images/logo_mtinfo.png"
            style={{ display: open ? "none" : "block" }}
          />
          <img
            src="images/logo_mtinfo_full.png"
            style={{ display: !open ? "none" : "block" }}
          />
        </DrawerHeader>
        <List sx={{ padding: 0 }}>
          <MiniDrawerListItem
            name={app.t("drawer.banners")}
            onClick={() => {
              nav("/banners");
            }}
            isOpen={open}
            icon={FlagIcon}
            isActive={location.pathname == "/banners"}
          />
          <MiniDrawerListItem
            name={app.t("drawer.templates")}
            onClick={() => {
              nav("/templates");
            }}
            isOpen={open}
            icon={ArticleIcon}
            isActive={location.pathname == "/templates"}
          />
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, marginTop: 10 }}>
        {children}
      </Box>
    </Box>
  );
}

type MiniDrawerListItemProps = {
  name: string;
  onClick: () => void;
  isOpen: boolean;
  icon: OverridableComponent<SvgIconTypeMap>;
  isActive: boolean;
};
const SquareEdgedPopover = styled(Popover)`
  width: 240px;
  border-radius: 0;
  .custom-popover-content {
    overflow: visible;
  }
`;
export const MiniDrawerListItem = (props: MiniDrawerListItemProps) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <ListItem
      data-testid="list-item"
      key={props.name}
      disablePadding
      sx={{
        display: "block",
        backgroundColor:
          props.isActive || isHovered ? "#1e282c" : "transparent",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handlePopoverOpen}
      onMouseOut={handlePopoverClose}
    >
      <ListItemButton
        onClick={props.onClick}
        selected={props.isActive}
        sx={{
          minHeight: 48,
          justifyContent: props.isOpen ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.isOpen ? 3 : "auto",
            justifyContent: "center",
            color: props.isActive ? "white" : "rgb(184, 199, 206)",
          }}
        >
          <props.icon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={props.name}
          sx={{ opacity: props.isOpen ? 1 : 0 }}
        />
      </ListItemButton>
      {isHovered && !props.isOpen && (
        <MouseOverPopover
          content={props.name}
          anchorEl={anchorEl}
          open={open}
          onClose={handlePopoverClose}
        />
      )}
    </ListItem>
  );
};
type MouseOverPopoverProps = {
  content: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
};
export const MouseOverPopover = ({
  content,
  anchorEl,
  open,
  onClose,
}: MouseOverPopoverProps) => {
  const widthDifference = drawerWidth - closedDrawerWidth;
  const lableWidth = `${widthDifference}px`;
  return (
    <>
      <SquareEdgedPopover
        data-testid="popover"
        id="mouse-over-popover"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          pointerEvents: "none",
          width: lableWidth,
          transition: "width 0.3s",
        }}
        classes={{ paper: "custom-popover-content" }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            width: lableWidth,
            height: "48px",
            p: 1,
            bgcolor: "#1e282c",
            color: "white",
            flexShrink: 0,
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            overflow: "hidden",
          }}
        >
          {content}
        </Typography>
      </SquareEdgedPopover>
    </>
  );
};
