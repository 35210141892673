import MultipleSelectCheckmarks from "../components/MultipleSelectMenuItem";
import { BannerFormLoaderData } from "pages/BannerForm";
import React from "react";
import { useLoaderData } from "react-router";
import dayjs from "dayjs";
import { Application } from "../models/application";
import { Area } from "../models/area";
import { useTranslation } from "react-i18next";

import {
  BannerFilter,
  BannerListParams,
  defaultBannerFilter,
} from "../models/banner";
import { Formik, Field, Form, FormikErrors, useFormikContext } from "formik";
import { Checkbox as FormikCheckbox, TextField } from "formik-mui";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { BannerListAction } from "../reducers/bannerListQuery";
import { debounce } from "lodash";
import { Typography, Divider, InputLabel } from "@mui/material";
import { DISPLAY_DATE_FORMAT } from "../constants/public";

type FilterBannersProps = {
  isTemplate?: boolean;
  dispatch: React.Dispatch<BannerListAction>;
};
interface DateTimePickerComponentProps {
  name: string;
  dispatch: React.Dispatch<BannerListAction>;
}
export const DatePickerComponent = ({
  name,
  dispatch,
}: DateTimePickerComponentProps) => {
  const { values, setFieldValue } = useFormikContext<BannerFilter>();

  const handleDateTimeChange = (date: Date | null) => {
    if (date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      dispatch({
        type: "update_filter",
        params: {
          ...values,
          [name]: formattedDate,
        },
      });
      setFieldValue(name, formattedDate);
    } else {
      dispatch({
        type: "update_filter",
        params: {
          ...values,
          [name]: "",
        },
      });
      setFieldValue(name, "");
    }
  };

  return (
    <>
      <Field
        component={DatePicker}
        slotProps={{ textField: { size: "small", margin: "dense" } }}
        id={name}
        name={name}
        onChange={handleDateTimeChange}
        format={DISPLAY_DATE_FORMAT}
      />
    </>
  );
};

export const FilterBanners = (props: FilterBannersProps) => {
  const { isTemplate, dispatch } = props;
  const { t } = useTranslation("app");
  const data = useLoaderData() as BannerFormLoaderData;
  const areaResponse = data.areas;

  const applicationResponse = data.applications;
  const appNames: string[] =
    applicationResponse?.data?.map((app: Application) => app.name) || [];
  const areaNames: string[] =
    areaResponse?.data?.map((area: Area) => area.name) || [];

  const validateContent = (): FormikErrors<BannerFilter> => {
    return {};
  };
  const handleSubmit = (values: BannerListParams) => {
    // Handle form submission
    console.log("values", values);
  };
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={defaultBannerFilter}
        validate={validateContent}
        validateOnChange={true}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <Typography fontWeight={"bold"} variant="button" gutterBottom>
                {t("listPage.filterSection")}
              </Typography>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="title">
                    {t("bannerFields.title")}
                  </InputLabel>
                  <Field
                    component={TextField}
                    id="title"
                    name="title"
                    placeholder={t("bannerFields.title")}
                    margin="dense"
                    size="small"
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;
                      setFieldValue("title", value);
                      const debouncedDispatch = debounce(() => {
                        dispatch({
                          type: "update_filter",
                          params: {
                            ...values,
                            title: value,
                          },
                        });
                      }, 500);
                      debouncedDispatch();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="message">
                    {t("bannerFields.message")}
                  </InputLabel>
                  <Field
                    component={TextField}
                    id="message"
                    name="message"
                    placeholder={t("bannerFields.message")}
                    fullWidth
                    margin="dense"
                    size="small"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;
                      setFieldValue("message", value);
                      const debouncedDispatch = debounce(() => {
                        dispatch({
                          type: "update_filter",
                          params: {
                            ...values,
                            message: value,
                          },
                        });
                      }, 500);
                      debouncedDispatch();
                    }}
                  />
                </Grid>
                {!isTemplate ? (
                  <>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="valid_from">
                        {t("bannerFields.validFrom")}
                      </InputLabel>
                      <DatePickerComponent
                        name={"valid_from"}
                        dispatch={dispatch}
                      />
                      {errors.valid_from && touched.valid_from && (
                        <Alert severity="error">{errors.valid_from}</Alert>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="valid_to">
                        {t("bannerFields.validTo")}
                      </InputLabel>
                      <DatePickerComponent
                        name={"valid_to"}
                        dispatch={dispatch}
                      />
                      {errors.valid_to && touched.valid_to && (
                        <Alert severity="error">{errors.valid_to}</Alert>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <InputLabel htmlFor="active">
                          {t("bannerFields.status")}
                        </InputLabel>
                        <Field
                          data-testid="active-checkbox"
                          type="checkbox"
                          component={FormikCheckbox}
                          id="active"
                          name="active"
                          onClick={() => {
                            dispatch({
                              type: "update_filter",
                              params: {
                                ...values,
                                active: !values.active, // Update the title field while keeping the rest of the values
                              },
                            });
                          }}
                        />
                        <label htmlFor="active">
                          {t("filters.activeBanners")}
                        </label>
                      </div>
                      <div>
                        <Field
                          data-testid="inactive-checkbox"
                          type="checkbox"
                          component={FormikCheckbox}
                          id="inactive"
                          name="inactive"
                          onClick={() => {
                            dispatch({
                              type: "update_filter",
                              params: {
                                ...values,
                                inactive: !values.inactive, // Update the title field while keeping the rest of the values
                              },
                            });
                          }}
                        />
                        <label htmlFor="inactive">
                          {t("filters.inactiveBanners")}
                        </label>
                      </div>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12}>
                  <InputLabel htmlFor="applications" sx={{ marginBottom: 1 }}>
                    {t("bannerFields.applications")}
                  </InputLabel>
                  <MultipleSelectCheckmarks
                    name="applications"
                    responseValues={appNames}
                    dispatch={dispatch}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="countries" sx={{ marginBottom: 1 }}>
                    {t("bannerFields.areas")}
                  </InputLabel>
                  <MultipleSelectCheckmarks
                    name="countries"
                    responseValues={areaNames}
                    dispatch={dispatch}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
