import React, { useEffect, useRef, JSX } from "react";
import { WebStorageStateStore } from "oidc-client-ts";
import {
  AuthContextProps,
  AuthProvider,
  hasAuthParams,
  useAuth,
} from "react-oidc-context";
import axios from "axios";
import apiClient from "../utils/apiClient";
import { enqueueSnackbar } from "notistack";
import getHostVars from "../utils/hostVars";

export const TOKEN_NAMESPACE = "DI_AC_TOKEN";

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

const signInCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const CustomAuthProvider = ({ children }: Props) => {
  const envVars = getHostVars(window.location.hostname);
  return (
    <AuthProvider
      automaticSilentRenew={true}
      userStore={new WebStorageStateStore()}
      authority={envVars.IDP_URL}
      client_id={envVars.IDP_CLIENT_ID}
      redirect_uri={envVars.IDP_REDIRECT_URL}
      post_logout_redirect_uri={envVars.IDP_REDIRECT_URL}
      scope="openid profile api"
      response_type="code"
      onSigninCallback={signInCallback}
    >
      {children}
    </AuthProvider>
  );
};

const AutoAuthWrapper = ({ children }: Props) => {
  const auth = useAuth();
  const interceptorRef = useRef<number>(-1);

  // Automatic Sign In
  // Refer: hasAuthParams https://github.com/authts/react-oidc-context#automatic-sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }
    // Set Interceptors
    if (interceptorRef.current == -1) {
      setInterceptors(auth);
      interceptorRef.current = 1;
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
  ]);

  useEffect(() => {
    if (!auth.user) return;
    localStorage.setItem(TOKEN_NAMESPACE, auth.user.access_token);
  }, [auth.user]);

  // Block if auth is intializing
  if (auth.isLoading || interceptorRef.current == -1) {
    return <p>Loading...</p>;
  }

  if (auth.activeNavigator) {
    return <div>Signing you in/out...</div>;
  }
  if (!auth.isAuthenticated) {
    return <div>Unable to log in</div>;
  }

  return <>{children}</>;
};

const setInterceptors = (auth: AuthContextProps) => {
  // Axios Response Interceptors for Auth
  apiClient.interceptors.response.use(
    (r) => r,
    async (e) => {
      if (!axios.isCancel(e)) {
        const { status, data } = e.response;
        switch (status) {
          case 401: {
            try {
              const user = await auth.signinSilent();
              if (!user) await auth.signinRedirect();
            } catch (err) {
              enqueueSnackbar("Silent sign-in failed, forcefully redirecting", {
                variant: "warning",
              });
              await auth.signoutRedirect();
            }
            break;
          }
          case 403: {
            window.location.assign("/unauthorized");
            break;
          }
          default:
            enqueueSnackbar(`Request failed with ${status} "${data}"`, {
              variant: "error",
            });
            break;
        }
        return Promise.reject(e);
      }
    },
  );
};

export { CustomAuthProvider, AutoAuthWrapper };
