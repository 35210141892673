import { createTheme } from "@mui/material/styles";

export const closedDrawerWidth = 50;
export const drawerWidth = 240;
export const appBarHeight = 60;

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    mtiAddButton: true;
  }
}

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#009DE0",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily:
      "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    h1: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
    },
    h2: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
    },
    h3: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
    },
    h4: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "1.375rem",
    },
    h5: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
    h6: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.125rem",
    },
    body1: {
      fontFamily:
        "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "15pt",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#1b3c4a",
          color: "white",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "rgb(27, 60, 74)",
          fontWeight: "bold",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "mtiAddButton" },
          style: {
            textAlign: "center",
            padding: "6px 5px 5px 5px",
            minWidth: "36px",
            height: "36px",
            backgroundColor: "#009DE0",
            color: "white",
            boxSizing: "border-box",
            "&:hover": {
              backgroundColor: "#0078A0",
            },
          },
        },
      ],
    },
  },
});
