import React from "react";

import { RouterHandle } from "../models/router";
import { Link as RouterLink, useMatches } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const AppBreadcrumbs = () => {
  const { t } = useTranslation("app");
  const matches = useMatches();
  const routeHandles = matches.map((r) => r.handle as RouterHandle);
  return (
    <BreadcrumbsContainer data-testid="breadrumbs-container">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {routeHandles.map((r, index) => (
          <Link
            key={r.title}
            underline="hover"
            color="inherit"
            to={matches[index].pathname}
            component={RouterLink}
            data-testid="breadrumbs-crumb"
          >
            {t(r.title)}
          </Link>
        ))}
      </Breadcrumbs>
    </BreadcrumbsContainer>
  );
};

const BreadcrumbsContainer = styled("div")({
  backgroundColor: "#FAFCFE",
  color: "#C3C6D2",
  padding: "10px 28px",
  marginBottom: 2,
  marginTop: 10,
  boxShadow: "0 2px 2px rgba(27,60,74,0.25)",
  borderTop: "1px solid gray",
});

export default AppBreadcrumbs;
