import { TOKEN_NAMESPACE } from "../auth";
import axios from "axios";

const apiClient = axios.create({
  baseURL: "/api",
  headers: {
    "Content-type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (c) => {
    const token = localStorage.getItem(TOKEN_NAMESPACE);
    if (token) {
      c.headers.Authorization = `Bearer ${token}`;
    }
    return c;
  },
  (e) => Promise.reject(e),
);

export default apiClient;
