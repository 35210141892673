import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import ReactDOM from "react-dom/client";
import { AutoAuthWrapper, CustomAuthProvider } from "./auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { App } from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ConfirmProvider } from "material-ui-confirm";
import "./index.css";
import "./utils/i18n";
import { ThemeProvider } from "@mui/material";
import { theme } from "./utils/theme";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <React.StrictMode>
    <CustomAuthProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AutoAuthWrapper>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SnackbarProvider
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <ConfirmProvider>
                  <App />
                </ConfirmProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </AutoAuthWrapper>
        </ThemeProvider>
      </QueryClientProvider>
    </CustomAuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
