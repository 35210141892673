import React from "react";
import { Stack, Typography } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const UnauthorizedUser = () => {
  const { t } = useTranslation("app");
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
      sx={{
        marginTop: 50,
      }}
    >
      <Lock
        data-testid="lock-icon"
        sx={{ fontSize: 48, color: "red", marginBottom: 1 }}
      />
      <Typography variant="h5">{t("errors.UnauthorizedUserError")}</Typography>
    </Stack>
  );
};

export default UnauthorizedUser;
