export type Language = {
  value: string;
  name: string;
};

const languages: Language[] = [
  { value: "en", name: "English" },
  { value: "fr", name: "French" },
  { value: "nl", name: "Dutch" },
  { value: "de", name: "German" },
];

export default languages;
