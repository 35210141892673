import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Paper, Box, Tab, SelectChangeEvent } from "@mui/material";
import Button from "@mui/material/Button";
import BannerContent from "./BannerContent";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { Banner, BannerContentType, LanguageCode } from "../../models/banner";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import languages from "../../constants/languages";
import { useTranslation } from "react-i18next";
import LanguageSelectDialog from "./LanguageDialog";
import AddIcon from "@mui/icons-material/Add";

const BannerContentArray = () => {
  const { values, errors, isSubmitting } = useFormikContext<Banner>();
  const { t } = useTranslation("app");
  const [currentTab, setCurrentTab] = React.useState("0");
  const [showOptions, setShowOptions] = React.useState(false);

  const handleOptionChange = (
    event: SelectChangeEvent<string>,
    helper: FieldArrayRenderProps,
  ) => {
    const newSelectedOption = event.target.value as LanguageCode;
    const newBannerContent: BannerContentType = {
      title: "",
      message: "",
      language_code: newSelectedOption,
    };
    helper.push(newBannerContent);
    setShowOptions(false);
    setCurrentTab(values.banner_content.length.toString());
  };

  return (
    <Paper elevation={3} style={{ padding: 16 }}>
      <FieldArray
        name="banner_content"
        render={(arrayHelpers: FieldArrayRenderProps) => (
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(e, v) => {
                  setCurrentTab(v);
                }}
                aria-label="Language tabs"
              >
                {values.banner_content.map((banner, index) => (
                  <Tab
                    key={banner.language_code}
                    value={index.toString()}
                    sx={{ display: "flex", alignItems: "center" }}
                    label={getBannerLang(banner)}
                    icon={
                      errors.banner_content && errors.banner_content[index] ? (
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          style={{ color: "#d32f2f" }}
                        />
                      ) : (
                        <></>
                      )
                    }
                    iconPosition="start"
                  />
                ))}
                <Button
                  disabled={
                    isSubmitting ||
                    values.banner_content.length == languages.length
                  }
                  variant="text"
                  onClick={() => setShowOptions(true)}
                  startIcon={<AddIcon />}
                  color="secondary"
                >
                  {t("forms.addLanguage")}
                </Button>
              </TabList>
            </Box>
            {values.banner_content.map((content, index) => (
              <TabPanel key={content.language_code} value={index.toString()}>
                <BannerContent
                  key={`content${content.language_code}`}
                  index={index}
                  content={content}
                />
                <Button
                  disabled={isSubmitting || content.language_code == "en"}
                  variant="contained"
                  onClick={() => {
                    arrayHelpers.remove(index);
                    setCurrentTab((index - 1).toString());
                  }}
                  sx={{ marginTop: 3 }}
                >
                  {t("forms.delete")}
                </Button>
                <LanguageSelectDialog
                  showOptions={showOptions}
                  handleCloseOptions={() => setShowOptions(false)}
                  handleOptionChange={(e) =>
                    handleOptionChange(e, arrayHelpers)
                  }
                  selectedOptions={values.banner_content.map(
                    (c) => c.language_code,
                  )}
                />
              </TabPanel>
            ))}
          </TabContext>
        )}
      />
    </Paper>
  );
};

const getBannerLang = (banner: BannerContentType): string | undefined => {
  return languages.find((l) => l.value == banner.language_code)?.name;
};

export default BannerContentArray;
