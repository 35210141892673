import React, { useEffect, useState } from "react";
import { Banner, bannerContentSorter } from "../models/banner";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";

type TemplatePickerProps = {
  templates: Banner[];
  onTemplateSelect: (banner: Banner) => void;
};

const TemplatePicker = ({
  templates,
  onTemplateSelect,
}: TemplatePickerProps) => {
  const { t } = useTranslation("app");
  const tCommon = useTranslation("common");
  const [selected, setSelected] = useState("-1");
  const confirm = useConfirm();
  const confirmText = {
    title: t("forms.templateSelector.confirmTitle"),
    description: t("forms.templateSelector.confirmBody"),
    confirmationText: tCommon.t("general.ok"),
    cancellationText: tCommon.t("general.cancel"),
  };

  useEffect(() => {
    const match = templates.find((b) => b.id == parseInt(selected));
    if (!match) return;

    confirm(confirmText)
      .then(() => {
        if (!match) return;
        match.banner_content.sort(bannerContentSorter);
        onTemplateSelect(match);
      })
      .catch(() => {
        setSelected("-1");
      });
  }, [selected]);

  return (
    <>
      <InputLabel id="template-select-label" sx={{ marginBottom: "10px" }}>
        {t("forms.templateSelector.title")}
      </InputLabel>
      <FormControl fullWidth>
        <Select
          data-testid="template-selector"
          labelId="template-select-label"
          id="template-select"
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
          }}
        >
          <MenuItem disabled value={-1}>
            {t("forms.templateSelector.placeholder")}
          </MenuItem>
          {templates.map((b) => (
            <MenuItem
              key={b.id}
              value={b.id}
              data-testid="template-selector-item"
            >
              {b.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default TemplatePicker;
