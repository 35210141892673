import React from "react";
import { FormLabel, Grid } from "@mui/material";
import { FastField } from "formik";
import { TextField } from "formik-mui";
import { BannerContentType } from "models/banner";
import { useTranslation } from "react-i18next";

export type BannerContentProps = {
  index: number;
  content: BannerContentType;
};
const BannerContent = ({ index, content }: BannerContentProps) => {
  const name = `banner_content[${index}]`;
  const { t } = useTranslation("app");
  if (index === 0) {
    content.language_code = "en";
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <FormLabel component="legend">{t("bannerFields.title")}</FormLabel>
      </Grid>
      <Grid item xs={12} sm={10}>
        <FastField
          component={TextField}
          id={`${name}.title`}
          name={`${name}.title`}
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormLabel component="legend">{t("bannerFields.message")}</FormLabel>
      </Grid>
      <Grid item xs={12} sm={10}>
        <div>
          <FastField
            component={TextField}
            id={`${name}.message`}
            name={`${name}.message`}
            multiline
            rows={6}
            fullWidth={true}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default BannerContent;
