export const allHostVars: HostVars = JSON.parse(
  process.env.REACT_APP_HOST_ENVS || "",
);

export type HostVar = {
  IDP_URL: string;
  IDP_CLIENT_ID: string;
  IDP_REDIRECT_URL: string;
  MTINFO_HOME_URL: string;
};

export type HostVars = {
  [key: string]: HostVar;
  default: HostVar;
};

const getHostVars = (hostname: string): HostVar => {
  return allHostVars[hostname] || allHostVars.default;
};

export default getHostVars;
