import FormHelperText from "@mui/material/FormHelperText";
import { useFormikContext } from "formik";
import { BannerFilter } from "models/banner";

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { BannerListAction } from "reducers/bannerListQuery";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface CheckMenuItemComponentProps {
  name: string;
  responseValues: string[];
  dispatch: React.Dispatch<BannerListAction>;
}

export default function MultipleSelectCheckmarks({
  name,
  responseValues,
  dispatch,
}: CheckMenuItemComponentProps) {
  const { values, setFieldValue } = useFormikContext<BannerFilter>();
  const { t } = useTranslation("app");
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    dispatch({
      type: "update_filter",
      params: {
        ...values,
        [name]: typeof value === "string" ? value.split(",") : value,
      },
    });
    setFieldValue(name, typeof value === "string" ? value.split(",") : value);
  };
  let currentFieldValue: string[] = [];
  let fieldLabel = "";
  switch (name) {
    case "applications":
      currentFieldValue = values.applications || [];
      fieldLabel = "forms.banner.applications";
      break;
    case "countries":
      currentFieldValue = values.countries || [];
      fieldLabel = "filters.countries";
      break;
    default:
      currentFieldValue = [];
      break;
  }
  return (
    <div>
      <FormControl fullWidth={true}>
        <Select
          id={name}
          name={name}
          data-testid="menu-selector"
          multiple
          value={currentFieldValue || []}
          onChange={handleChange}
          renderValue={(selected) => selected.join(",")}
          MenuProps={MenuProps}
          fullWidth={true}
          placeholder={t(fieldLabel) || ""}
          size="small"
          margin="dense"
        >
          {responseValues.map((v) => (
            <MenuItem data-testid="menu-selector-item" key={v} value={v}>
              <Checkbox
                checked={currentFieldValue && currentFieldValue.indexOf(v) > -1}
              />
              <ListItemText primary={v} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
interface MultiMenuItemComponentProps {
  name: string;
  responseValues: string[];
  labelRenderer: (
    name: string,
    values: BannerFilter,
  ) => { label: string; currentFieldValue: string[] };
  errors: string | string[] | undefined;
  touched: boolean | undefined;
}
export function MultipleSelectMenuItem({
  name,
  responseValues,
  labelRenderer,
  errors,
  touched,
}: MultiMenuItemComponentProps) {
  const { values, setFieldValue } = useFormikContext<BannerFilter>();
  const { t } = useTranslation("app");
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    setFieldValue(name, typeof value === "string" ? value.split(",") : value);
  };

  const { label, currentFieldValue } = labelRenderer(name, values);
  return (
    <>
      <FormControl error={(errors && touched) || false}>
        <Select
          id={name}
          name={name}
          data-testid="menu-selector"
          multiple
          displayEmpty
          value={currentFieldValue || []}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{t(label)}</em>;
            }
            return selected.join(", ");
          }}
        >
          {responseValues.map((v) => (
            <MenuItem data-testid="menu-selector-item" key={v} value={v}>
              <Checkbox
                checked={currentFieldValue && currentFieldValue.indexOf(v) > -1}
              />
              <ListItemText primary={v} />
            </MenuItem>
          ))}
        </Select>
        {errors && touched && (
          <FormHelperText error={true}>{errors}</FormHelperText>
        )}
      </FormControl>
    </>
  );
}
