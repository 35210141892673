import React, { Suspense } from "react";

import { Outlet, useMatches, useNavigation } from "react-router-dom";
import Container from "@mui/material/Container";
import MiniDrawer from "./Drawer";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { RouterHandle } from "../models/router";
import AppBreadcrumbs from "./AppBreadcrumbs";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

export const MainLayout = () => {
  const matches = useMatches();
  const { t } = useTranslation("app");
  const currentHandle = matches[matches.length - 1].handle as RouterHandle;

  return (
    <>
      <MiniDrawer>
        <Container maxWidth={false}>
          <Typography variant="h1" data-testid="page-title">
            {t(currentHandle.title)}
          </Typography>
        </Container>
        <AppBreadcrumbs />
        <Container
          maxWidth={false}
          data-testid="main-container"
          sx={{ paddingTop: 5 }}
        >
          <BackdropLoader />
          <Suspense fallback={<CircularProgress disableShrink />}>
            <Outlet />
          </Suspense>
        </Container>
      </MiniDrawer>
    </>
  );
};

const BackdropLoader = () => {
  const { state } = useNavigation();
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer }}
      open={state === "loading"}
      data-testid="loading-backdrop"
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
