import React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountMenu from "./AvatarMenu";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { appBarHeight, closedDrawerWidth, drawerWidth } from "../utils/theme";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: closedDrawerWidth,
  width: `calc(100% - ${closedDrawerWidth}px)`,
  height: appBarHeight,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface AppBarComponentProps {
  open: boolean;
  handleDrawer: () => void;
}

export default function AppBarComponent({
  open,
  handleDrawer,
}: AppBarComponentProps) {
  const app = useTranslation("app");
  const { user } = useAuth();

  return (
    <AppBar position="fixed" open={open} elevation={0}>
      <Toolbar>
        <MenuItem onClick={handleDrawer} data-testid="menu-button" dense={true}>
          {open ? <ArrowBackIcon /> : <MenuIcon />}
        </MenuItem>
        <div style={{ flexGrow: 1 }} />
        <Typography variant="body1">
          <strong>
            {user?.profile.name}
            <br />
          </strong>
          {app.t("Dual Inventive")}
        </Typography>
        <AccountMenu></AccountMenu>
      </Toolbar>
    </AppBar>
  );
}
