import Tooltip from "@mui/material/Tooltip";
import FormHelperText from "@mui/material/FormHelperText";
import { MultipleSelectMenuItem } from "../components/MultipleSelectMenuItem";
import { useConfirm } from "material-ui-confirm";
import languages from "../constants/languages";
import React, { useEffect, useState } from "react";
import utc from "dayjs/plugin/utc";
import {
  BannerResponse,
  createBanner,
  deleteBanner,
  enrichBanner,
  getAxiosError,
  getBanner,
  getBanners,
  memoizedGetApplications,
  memoizedGetAreas,
  updateBanner,
} from "../api/banners";
import { Application } from "../models/application";
import { Area } from "../models/area";
import BannerContentArray from "../components/forms/BannerContentArray";
import {
  Banner,
  BannerFilter,
  buildValidationSchema,
  defaultBanner,
  defaultTemplate,
  getDefaultContent,
} from "../models/banner";
import { Formik, Field, Form, FormikHelpers, FormikErrors } from "formik";
import { Switch, TextField } from "formik-mui";
import {
  Button,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Alert,
} from "@mui/material";
import { DateTimePicker } from "formik-mui-x-date-pickers";
import {
  Navigate,
  Params,
  useLoaderData,
  useNavigate,
  useRouteError,
} from "react-router";
import { enqueueSnackbar } from "notistack";
import { truncate, upperFirst } from "lodash";
import { useTranslation } from "react-i18next";
import TemplatePicker from "../components/TemplatePicker";
import {
  CenteredDiv,
  SpacedGrid,
  SpacedLabel,
} from "./styled/BannerFormContent";
import dayjs from "dayjs";
import { DISPLAY_TIME_FORMAT } from "../constants/public";
import axios from "axios";
import { createSearchParams, useSearchParams } from "react-router-dom";

type BannerFormProps = {
  isEdit?: boolean;
  isTemplate?: boolean;
};

// Query parameter for loading default value to form
const BANNER_SEARCH_DEFAULT = "default";

export const BannerFormLoader = async ({
  params,
}: {
  params: Params<string>;
}): Promise<BannerFormLoaderData> => {
  return {
    banner: params["id"] ? await getBanner(params["id"]) : undefined,
    applications: await memoizedGetApplications(),
    areas: await memoizedGetAreas(),
    templates: !params["id"]
      ? await getBanners({ is_template: true })
      : undefined,
  };
};

export const BannerErrorElement = ({ isTemplate }: BannerFormProps) => {
  const error = useRouteError();
  const axiosError = getAxiosError(error);
  const { t } = useTranslation("app");
  useEffect(() => {
    if (axiosError?.code == axios.AxiosError.ERR_BAD_REQUEST) {
      enqueueSnackbar(
        t("errors.notFoundError", {
          type: isTemplate
            ? t("bannerTypes.template")
            : t("bannerTypes.banner"),
        }),
        { variant: "error" },
      );
    } else {
      enqueueSnackbar(t("errors.unhandledError"), { variant: "error" });
      console.error("Unhandled Exception In Banner Loader", error);
    }
  }, []);
  return <Navigate to="/" replace={true} />;
};

dayjs.extend(utc);

export const disablePastDays = (date: Date) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  return date < currentDate;
};

export const BannerForm = ({ isEdit, isTemplate }: BannerFormProps) => {
  const data = useLoaderData() as BannerFormLoaderData;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation("app");

  const [isInitiallyRecreatable, setIsInitiallyRecreatable] = useState(true);
  useEffect(() => {
    setIsInitiallyRecreatable(!data.banner?.status);
  }, []);

  const [banner, setBanner] = useState<Banner>();

  // Load from loader data or get defaults specifed in search params
  useEffect(() => {
    if (data.banner) {
      setBanner(data.banner);
    } else if (searchParams.has(BANNER_SEARCH_DEFAULT)) {
      const queryDefaults = JSON.parse(
        searchParams.get(BANNER_SEARCH_DEFAULT) || "",
      );
      setBanner(enrichBanner(queryDefaults));
    }
  }, []);

  const validateContent = (values: Banner): FormikErrors<Banner> => {
    const languagesArray: string[] = [];
    const errors: FormikErrors<Banner> = {};
    values.banner_content.forEach((content) => {
      if (content.language_code === "") {
        return;
      }
      if (languagesArray.includes(content.language_code)) {
        const duplicateLanguage = languages.find(
          (item) => item.value === content.language_code,
        );
        errors.banner_content = `${t("errors.duplicateLanguageCodeError", {
          language: duplicateLanguage?.name,
        })}`;
      }
      languagesArray.push(content.language_code);
    });
    if (!languagesArray.includes("en")) {
      errors.banner_content = "errors.englishRequiredError";
    }
    if (
      !values.id &&
      !values.is_template &&
      values.type == "incident" &&
      !values.status
    ) {
      errors.status = "errors.incidentStatusFalseError";
    }
    return errors;
  };

  const defaultBannerValues = isTemplate ? defaultTemplate : defaultBanner;
  const tCommon = useTranslation("common");
  const confirm = useConfirm();
  const promptRecreate = async (banner: Banner, id: number | undefined) => {
    if (id === undefined) return;
    const confirmText = {
      title: t("forms.deleteConfirm.confirmTitle"),
      description: t("forms.deleteConfirm.confirmBody", {
        type: banner.is_template ? "Template" : "Banner",
        name: getDefaultContent(banner)?.title,
      }),
      confirmationText: tCommon.t("general.ok"),
      cancellationText: tCommon.t("general.cancel"),
    };
    confirm(confirmText)
      .then(async () => {
        const type = upperFirst(isTemplate ? "Template" : "Banner");
        const status = await deleteBanner(id);
        if (status == 204) {
          enqueueSnackbar(
            `${t("success.successfullyDeletedToRecreate", {
              type: type,
            })}`,
            {
              variant: "success",
            },
          );
          const newBanner = { id: undefined, ...banner };
          if (newBanner.type == "incident") {
            newBanner.valid_from = undefined;
            newBanner.valid_to = undefined;
          }
          // Replace path to new banner from with defaults set
          navigate(
            {
              pathname: isTemplate ? "/templates/new" : "/banners/new",
              search: createSearchParams({
                [BANNER_SEARCH_DEFAULT]: JSON.stringify(newBanner),
              }).toString(),
            },
            {
              replace: true,
            },
          );
          setBanner(newBanner);
        } else {
          enqueueSnackbar(
            `${t("errors.FailedToDeleteError", {
              type: type,
              name: getDefaultContent(banner)?.title,
            })}`,
            {
              variant: "error",
            },
          );
        }
      })
      .catch(() => {
        return;
      });
  };
  const renderMenuFieldLabel = (
    name: string,
    values: BannerFilter,
  ): { label: string; currentFieldValue: string[] } => {
    let currentFieldValue: string[] = [];
    let label = "Default Placeholder";
    switch (name) {
      case "applications":
        currentFieldValue = values.applications || [];
        label =
          currentFieldValue.length !== 0
            ? currentFieldValue.join(", ")
            : "forms.banner.selectApplications";
        break;
      case "countries":
        currentFieldValue = values.countries || [];
        label =
          currentFieldValue.length !== 0
            ? currentFieldValue.join(", ")
            : "forms.banner.selectAreas";
        break;
      default:
        currentFieldValue = [];
        break;
    }
    return { label, currentFieldValue };
  };
  return (
    <Formik
      enableReinitialize
      initialValues={banner ? banner : defaultBannerValues}
      validate={validateContent}
      validationSchema={() => buildValidationSchema(t)}
      onSubmit={async (
        values: Banner,
        { setSubmitting, setFieldValue }: FormikHelpers<Banner>,
      ) => {
        const subType = values.is_template ? "template" : "banner";
        // Auto generate banner name
        if (!values.is_template) {
          values.name = truncate(getDefaultContent(values)?.title, {
            length: 100,
          });
        }
        if (isEdit) {
          await updateBanner(values);
          enqueueSnackbar(
            `${t("success.successfullyUpdated", {
              type: upperFirst(subType),
            })}`,
            {
              variant: "success",
            },
          );
          navigate(`/${subType}s/${values?.id}`);
        } else {
          const result = await createBanner(values);
          await setFieldValue("id", result, false);
          enqueueSnackbar(
            `${t("success.successfullyCreated", {
              type: upperFirst(subType),
            })}`,
            {
              variant: "success",
            },
          );
          navigate(`/${subType}s`);
        }
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, isSubmitting, setValues }) => (
        <Form>
          <Grid
            container
            xs={12}
            sm={12}
            md={8}
            rowSpacing={2}
            sx={{ marginBottom: 2 }}
          >
            {isEdit && !isTemplate && (
              <Grid item md={12}>
                <Alert severity="warning">
                  {data.banner?.type == "maintenance" &&
                    t("warnings.provisionerUpdateWarning") +
                      t("warnings.maintenanceBannerProvisionerWarning")}
                  {data.banner?.type == "incident" &&
                    t("warnings.provisionerUpdateWarning")}
                </Alert>
              </Grid>
            )}
            {data.templates && !isTemplate ? (
              <Grid container direction="row-reverse" xs={12} sm={12}>
                <Grid item xs={12} sm={3}>
                  <TemplatePicker
                    templates={data.templates.data}
                    onTemplateSelect={(b) => {
                      setValues({
                        ...values,
                        banner_content: b.banner_content,
                        applications: b.applications,
                        countries: b.countries,
                        type: b.type,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12}>
              {errors.status && touched.status && (
                <Alert severity="error">{t(errors.status)}</Alert>
              )}
            </Grid>
            <Grid container xs={12} sm={12}>
              <Grid item xs={12} sm={3}>
                <Field as={RadioGroup} name="type">
                  <FormControl
                    component="fieldset"
                    disabled={isEdit || false}
                    data-testid="banner_types"
                    title={
                      isEdit ? t("errors.canNotChangeTypeError") : undefined
                    }
                  >
                    <FormLabel component="legend">Type</FormLabel>
                    <FormControlLabel
                      value="incident"
                      name="type"
                      control={<Radio />}
                      label="Incident"
                    />
                    <FormControlLabel
                      value="maintenance"
                      name="type"
                      control={<Radio />}
                      label="Maintenance"
                      data-testid="maintenance_radio"
                    />
                  </FormControl>
                </Field>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                sx={isTemplate ? { display: "none" } : {}}
              >
                <FormLabel>{t(`forms.${values.type}Settings`)}</FormLabel>
                {values.type === "maintenance" ? (
                  <SpacedGrid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CenteredDiv>
                        <SpacedLabel htmlFor="valid_from">
                          Valid From [UTC]
                        </SpacedLabel>
                        <Field
                          disabled={isEdit}
                          component={DateTimePicker}
                          shouldDisableDate={disablePastDays}
                          timezone="UTC"
                          ampm={false}
                          id="valid_from"
                          name="valid_from"
                          format={DISPLAY_TIME_FORMAT}
                          slotProps={{ textField: { fullWidth: true } }}
                        />
                      </CenteredDiv>
                      {errors.valid_from && (
                        <FormHelperText error style={{ textAlign: "center" }}>
                          {errors.valid_from}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CenteredDiv>
                        <SpacedLabel htmlFor="valid_to">
                          Valid To [UTC]
                        </SpacedLabel>
                        <Field
                          disabled={isEdit}
                          component={DateTimePicker}
                          shouldDisableDate={disablePastDays}
                          timezone="UTC"
                          id="valid_to"
                          name="valid_to"
                          ampm={false}
                          format={DISPLAY_TIME_FORMAT}
                          slotProps={{ textField: { fullWidth: true } }}
                          error={true}
                          helperText={"error"}
                        />
                      </CenteredDiv>
                      {errors.valid_to && (
                        <FormHelperText error style={{ textAlign: "center" }}>
                          {errors.valid_to}
                        </FormHelperText>
                      )}
                    </Grid>
                  </SpacedGrid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <div>
                        <FormLabel htmlFor="status">
                          {t("forms.active")}
                        </FormLabel>
                        <Field
                          type="checkbox"
                          component={Switch}
                          id="status"
                          name="status"
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
                <br />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: isTemplate ? "block" : "none" }}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} sm={2}>
                  <FormLabel htmlFor="name">{t("forms.banner.name")}</FormLabel>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Field
                    component={TextField}
                    id="name"
                    name="name"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <BannerContentArray />
              <br />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={2}>
                  <FormLabel htmlFor="applications">
                    {t("forms.banner.applications")}
                  </FormLabel>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <MultipleSelectMenuItem
                    name="applications"
                    responseValues={
                      data.applications?.data?.map(
                        (app: Application) => app.name,
                      ) || []
                    }
                    labelRenderer={renderMenuFieldLabel}
                    errors={errors.applications}
                    touched={touched.applications}
                  />
                </Grid>
              </Grid>
            </Grid>
            {values.type === "maintenance" && !isTemplate && (
              <>
                <Grid item xs={12} sm={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={2}>
                      <FormLabel htmlFor="app_visibility">
                        {t("forms.applicationVisibility")}
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Field
                        type="checkbox"
                        component={Switch}
                        id="app_visibility"
                        name="app_visibility"
                        data-testid="app_visibility"
                        disabled={isEdit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!isEdit || values.metadata ? (
                  <Grid item xs={12} sm={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={2}>
                        <FormLabel htmlFor="metadata.email_alerts">
                          {t("forms.emailOptOut")}
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <Field
                          type="checkbox"
                          component={Switch}
                          id="metadata.email_alerts"
                          name="metadata.email_alerts"
                          data-testid="email_status"
                          disabled={isEdit}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
              </>
            )}
            <Grid item xs={12} sm={12}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={2}>
                  <FormLabel htmlFor="countries">Areas</FormLabel>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <MultipleSelectMenuItem
                    name="countries"
                    responseValues={
                      data.areas?.data?.map((area: Area) => area.name) || []
                    }
                    labelRenderer={renderMenuFieldLabel}
                    errors={errors.countries}
                    touched={touched.countries}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={8} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    data-testid="submit_button"
                  >
                    {isEdit ? t("forms.update") : t("forms.submit")}
                  </Button>
                </Grid>
                {isEdit && !isTemplate && (
                  <Grid item xs={12} sm={2} md={4}>
                    <Tooltip
                      title={
                        values.status
                          ? t("warnings.canNotRecreateActiveBannerWarning")
                          : t("forms.deleteAndRecreate", {
                              type: values.is_template ? "template" : "banner",
                            })
                      }
                      placement="top"
                      data-testid="recreate-tool-tip`"
                    >
                      <span>
                        <Button
                          disabled={!isInitiallyRecreatable}
                          variant="contained"
                          type="button"
                          color={"error"}
                          onClick={() => promptRecreate(values, values.id)}
                        >
                          {t("forms.recreate")}
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export type BannerFormLoaderData = {
  banner?: Banner;
  applications: BannerResponse<Application[]>;
  areas: BannerResponse<Area[]>;
  templates?: BannerResponse<Banner[]>;
};
