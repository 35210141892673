import {
  faPencilAlt,
  faTrashAlt,
  faEyeSlash,
  faBellSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { TableBody, Skeleton, Chip, TableRow, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Banner, getDefaultContent } from "../models/banner";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import {
  MtiTable,
  MtiTableHead,
  MtiTableHeadCell,
  MtiTableRow,
  MtiTableCell,
} from "./styled/Table";
import { range } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DISPLAY_TIME_FORMAT } from "../constants/public";

type BannerTableProps = {
  isLoading: boolean;
  banners: Banner[];
  defaultCount: number;
  isTemplate?: boolean;
  onClick: (id?: number) => void;
  onDeleteClick: (isTemplate: boolean, name: string, id?: number) => void;
};

dayjs.extend(utc);

const BannerTable = (props: BannerTableProps) => {
  const { t } = useTranslation("app");
  const {
    isLoading,
    banners,
    defaultCount,
    isTemplate,
    onClick,
    onDeleteClick,
  } = props;
  return (
    <MtiTable sx={{ minWidth: 650 }} aria-label="banner list" size="small">
      <MtiTableHead>
        <TableRow>
          {isTemplate && (
            <MtiTableHeadCell>{t("bannerFields.name")}</MtiTableHeadCell>
          )}
          <MtiTableHeadCell>{t("bannerFields.title")}</MtiTableHeadCell>
          <MtiTableHeadCell>{t("bannerFields.message")}</MtiTableHeadCell>
          <MtiTableHeadCell>{t("bannerFields.type")}</MtiTableHeadCell>
          {!isTemplate && (
            <>
              <MtiTableHeadCell>{t("bannerFields.status")}</MtiTableHeadCell>
              <MtiTableHeadCell>{t("bannerFields.validFrom")}</MtiTableHeadCell>
              <MtiTableHeadCell>{t("bannerFields.validTo")}</MtiTableHeadCell>
            </>
          )}
          <MtiTableHeadCell>{t("bannerFields.action")}</MtiTableHeadCell>
        </TableRow>
      </MtiTableHead>
      <TableBody>
        {renderBanners(
          banners,
          isLoading,
          isTemplate,
          onClick,
          onDeleteClick,
          t,
          defaultCount,
        )}
      </TableBody>
    </MtiTable>
  );
};
const renderBanners = (
  banners: Banner[] | undefined,
  isLoading: boolean,
  isTemplate: boolean | undefined,
  onClick: (id?: number) => void,
  onDeleteClick: (isTemplate: boolean, name: string, id?: number) => void,
  t: (s: string) => string,
  defaultCount: number,
) => {
  if (isLoading) {
    return (
      <TableSkeletonLoader
        count={defaultCount}
        isTemplate={isTemplate || false}
      />
    );
  } else if (banners && banners.length > 0) {
    return banners.map((i) => (
      <BannerRow
        row={i}
        onClick={onClick}
        onDeleteClick={onDeleteClick}
        key={i.id}
        t={t}
      />
    ));
  } else {
    return <MergedRow numOfColumns={isTemplate ? 4 : 6} t={t} />;
  }
};
const TableSkeletonLoader = (props: { count: number; isTemplate: boolean }) => (
  <>
    {range(props.count).map((index) => (
      <MtiTableRow key={index}>
        {props.isTemplate && (
          <MtiTableCell>
            <Skeleton variant="text" data-testid="skeleton_name" />
          </MtiTableCell>
        )}
        <MtiTableCell>
          <Skeleton variant="text" data-testid="skeleton_title" />
        </MtiTableCell>
        <MtiTableCell>
          <Skeleton variant="text" data-testid="skeleton_message" />
        </MtiTableCell>
        <MtiTableCell>
          <Skeleton variant="text" data-testid="skeleton_type" />
        </MtiTableCell>
        {!props.isTemplate ? (
          <>
            <MtiTableCell>
              <Skeleton variant="text" data-testid="skeleton_status" />
            </MtiTableCell>
            <MtiTableCell>
              <Skeleton variant="text" data-testid="skeleton_validFrom" />
            </MtiTableCell>
            <MtiTableCell>
              <Skeleton variant="text" data-testid="skeleton_validTo" />
            </MtiTableCell>
          </>
        ) : null}
        <MtiTableCell>
          <Skeleton variant="text" data-testid="skeleton_actions" />
        </MtiTableCell>
      </MtiTableRow>
    ))}
  </>
);

type BannerRowProps = {
  row: Banner;
  onClick: (id?: number) => void;
  onDeleteClick: (isTemplate: boolean, name: string, id?: number) => void;
  t: (s: string) => string;
};

const BannerRow = (props: BannerRowProps) => {
  const { row, onClick, onDeleteClick, t } = props;
  const content = getDefaultContent(row);
  const navigate = useNavigate();
  const subType = row.is_template ? "template" : "banner";
  return (
    <MtiTableRow
      key={row.id}
      hover={true}
      onClick={() => onClick(row?.id)}
      sx={{ cursor: "pointer" }}
    >
      {row.is_template && (
        <>
          <MtiTableCell data-testid="banner_row_name">{row?.name}</MtiTableCell>
        </>
      )}
      <MtiTableCell data-testid="banner_row_title">
        {truncate(content?.title)}
      </MtiTableCell>
      <MtiTableCell data-testid="banner_row_message">
        {truncate(content?.message)}
      </MtiTableCell>
      <MtiTableCell data-testid="banner_row_type">{row.type}</MtiTableCell>
      {!row.is_template && (
        <>
          <MtiTableCell align="center">
            <BannerRowStatusChip row={row} t={t} />
          </MtiTableCell>
          <MtiTableCell data-testid="banner_row_validFrom">
            {!row.valid_from || row.type === "incident"
              ? "-"
              : dayjs(row?.valid_from).utc().format(DISPLAY_TIME_FORMAT)}
          </MtiTableCell>
          <MtiTableCell data-testid="banner_row_validTo">
            {!row.valid_to || row.type === "incident"
              ? "-"
              : dayjs(row?.valid_to).utc().format(DISPLAY_TIME_FORMAT)}
          </MtiTableCell>
        </>
      )}
      <MtiTableCell data-testid="skeleton_actions">
        <Tooltip
          title={t("forms.edit")}
          placement="top"
          data-testid={`edit-tool-tip-${row.id}`}
        >
          <IconButton
            data-testid={`edit-button-${row.id}`}
            aria-label={t("bannerFields.delete")}
            onClick={async (event) => {
              event.stopPropagation();
              navigate(`/${subType}s/${row?.id}`);
            }}
            size="small"
          >
            <FontAwesomeIcon style={{ fontSize: "14px" }} icon={faPencilAlt} />
          </IconButton>
        </Tooltip>
        <Tooltip
          data-testid={`delete-tool-tip-${row.id}`}
          title={
            row.status
              ? t("warnings.canNotDeleteActiveBannerWarning")
              : t("forms.delete")
          }
          placement="top"
        >
          <span>
            <IconButton
              data-testid={`delete-button-${row.id}`}
              disabled={row.status}
              aria-label={t("bannerFields.delete")}
              onClick={(event) => {
                event.stopPropagation();
                onDeleteClick(
                  row.is_template,
                  row.banner_content[0].title,
                  row?.id,
                );
              }}
              size="small"
            >
              <FontAwesomeIcon style={{ fontSize: "14px" }} icon={faTrashAlt} />
            </IconButton>
          </span>
        </Tooltip>
        {!row.app_visibility && (
          <Tooltip
            title={t("forms.hiddenIcon")}
            placement="top"
            data-testid={`hidden-tool-tip-${row.id}`}
          >
            <IconButton data-testid={`hidden-icon-${row.id}`} size="small">
              <FontAwesomeIcon style={{ fontSize: "14px" }} icon={faEyeSlash} />
            </IconButton>
          </Tooltip>
        )}
        {!row.metadata?.email_alerts ? (
          <Tooltip
            title={t("forms.alertsDisabledIcon")}
            placement="top"
            data-testid={`alerts-tool-tip-${row.id}`}
          >
            <IconButton data-testid={`alerts-icon-${row.id}`} size="small">
              <FontAwesomeIcon
                style={{ fontSize: "14px" }}
                icon={faBellSlash}
              />
            </IconButton>
          </Tooltip>
        ) : (
          []
        )}
      </MtiTableCell>
    </MtiTableRow>
  );
};

type BannerRowStatusChipProps = {
  row: Banner;
  t: (s: string) => string;
};

const BannerRowStatusChip = (props: BannerRowStatusChipProps) => (
  <Chip
    data-testid="banner_row_status"
    color={props.row.status ? "success" : "default"}
    size="small"
    label={
      props.row.status
        ? props.t("bannerFields.statusBool.true")
        : props.t("bannerFields.statusBool.false")
    }
  />
);

type MergedRowProps = {
  numOfColumns: number;
  t: (s: string) => string;
};

const MergedRow = ({ numOfColumns, t }: MergedRowProps) => {
  return (
    <MtiTableRow>
      <MtiTableCell colSpan={numOfColumns} align="center">
        {t("listPage.noResults")}
      </MtiTableCell>
    </MtiTableRow>
  );
};
export default BannerTable;
