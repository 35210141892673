import { BannerListParams } from "models/banner";

type UpdateBannerListAction = {
  type: "update_filter";
  params: BannerListParams;
};

export type BannerListAction =
  | { type: "per_page"; per_page: number }
  | { type: "page"; page: number }
  | { type: "reset"; init: BannerListParams }
  | UpdateBannerListAction;

const getStatusVal = (action: UpdateBannerListAction): number | undefined => {
  if (action.params.inactive && !action.params.active) {
    return 0;
  }
  if (!action.params.inactive && action.params.active) {
    return 1;
  }
  return undefined;
};

const getApplicationVal = (
  action: UpdateBannerListAction,
): string | undefined => {
  return action.params.applications && action.params.applications.length !== 0
    ? action.params.applications.join(",")
    : undefined;
};

const getCountryVal = (action: UpdateBannerListAction): string | undefined => {
  return action.params.countries && action.params.countries.length !== 0
    ? action.params.countries.join(",")
    : undefined;
};

export const BannerListQueryReducer = (
  state: BannerListParams,
  action: BannerListAction,
): BannerListParams => {
  let titleVal: string | undefined;
  let messageVal: string | undefined;
  let statusVal: number | undefined;
  let applicationVal: string | undefined;
  let countryVal: string | undefined;
  let validFromVal: string | undefined;
  let validToVal: string | undefined;
  switch (action.type) {
    case "reset":
      return action.init;
    case "per_page":
      return {
        ...state,
        per_page: action.per_page,
        page: 0,
      };
    case "page":
      return {
        ...state,
        page: action.page,
      };
    case "update_filter":
      titleVal = action.params.title;
      messageVal = action.params.message;
      validFromVal = action.params.valid_from;
      validToVal = action.params.valid_to;
      statusVal = getStatusVal(action);
      applicationVal = getApplicationVal(action);
      countryVal = getCountryVal(action);

      if (action.params.valid_to === "") {
        validToVal = undefined;
      }
      if (action.params.valid_from === "") {
        validFromVal = undefined;
      }
      if (action.params.title === "") {
        titleVal = undefined;
      }
      if (action.params.message === "") {
        messageVal = undefined;
      }
      return {
        ...state,
        page: 0,
        title: titleVal,
        message: messageVal,
        status: statusVal,
        application: applicationVal,
        country: countryVal,
        valid_from: validFromVal,
        valid_to: validToVal,
      };
  }
};

export const FilterBannersInitial: BannerListParams = {
  is_template: false,
  per_page: 5,
  page: 0,
  sort: "created_at",
  sort_dir: "desc",
};

export const FilterTemplatesInitial: BannerListParams = {
  is_template: true,
  per_page: 5,
  page: 0,
  sort: "created_at",
  sort_dir: "desc",
};
