import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import languages from "../../constants/languages";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";

type LanguageSelectDialogProps = {
  showOptions: boolean;
  selectedOptions: string[];
  handleCloseOptions: () => void;
  handleOptionChange: (event: SelectChangeEvent<string>) => void;
};

const LanguageSelectDialog = ({
  showOptions,
  selectedOptions,
  handleCloseOptions,
  handleOptionChange,
}: LanguageSelectDialogProps) => {
  const { t } = useTranslation("app");
  const availableLangs = languages.filter(
    (l) => !selectedOptions.includes(l.value),
  );
  return (
    <Dialog open={showOptions} onClose={handleCloseOptions}>
      <DialogTitle>{t("forms.banner.language")}</DialogTitle>
      <DialogContent>
        <FastField
          component={Select}
          onChange={handleOptionChange}
          displayEmpty
          defaultValue={""}
        >
          <MenuItem disabled value={""}>
            {t("forms.banner.selectLanguages")}
          </MenuItem>
          {availableLangs.map((language) => (
            <MenuItem
              key={language.value}
              value={language.value}
              data-name={language.name}
            >
              {language.name}
            </MenuItem>
          ))}
        </FastField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseOptions}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageSelectDialog;
