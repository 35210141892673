import styled from "@emotion/styled";
import { FormLabel, Grid } from "@mui/material";
import { theme } from "../../utils/theme";

export const SpacedGrid = styled(Grid)({
  marginTop: theme.spacing(0),
});

export const CenteredDiv = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  justifyContent: "space-between",
});

export const SpacedLabel = styled(FormLabel)({
  marginRight: theme.spacing(1),
  width: "fit-content",
  whiteSpace: "nowrap",
});
